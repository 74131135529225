import store from '@/store'
import http from '@/plugins/axios'
import { notificationSuccess, notificationError } from '@/utils/notification'

class Auth {
  constructor() {
    this.http = http
  }

  login(body) {
    return new Promise(async (resolve, reject) => {
      return await this.http.post('/auth', body)
      .then(async ({ data }) => {
        await store.dispatch('setUserLogged', data)
        window.localStorage.setItem('user_session', JSON.stringify(data))
        notificationSuccess('Sucesso!', `Login realizado com sucesso!`)
        resolve(data)
      })
      .catch((err) =>  {
        console.log(err)
        notificationError('Erro!', `${err.response.data.Error}`)
        reject(err)
        setTimeout(() => {
          location.reload()
        }, 500)
      })
    })
  }
}

export default Auth