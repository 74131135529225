<template>
  <div class="card-container">
    <div class="title-container">
      <img src="../assets/dcc.png" alt="Logo da Corregedoria da PMDF" />
      <div class="title-content">
        <h1 class="text-xl text-blue-900 font-bold">
          Plataforma do Departamento de Controle e Correição - DCC
        </h1>
        <h3 class="text-lg text-blue-900 font-normal">
          Polícia Militar do Distrito Federal
        </h3>
      </div>
    </div>
    <el-card class="card-content">
      <div class="card-title">
        <h1 class="text-3xl text-blue-900 font-bold">Login</h1>
      </div>
      <el-form label-position="top" :model="login">
        <el-form-item
          label="CPF"
          prop="cpf.value"
          :rules="[
            {
              required: true,
              message: 'Por favor, informe apenas os números do CPF.',
              trigger: ['blur'],
            },
            {
              min: 11, max: 11,
              message: 'O CPF deve possuir 11 caracteres.',
              trigger: ['blur'],
            }
          ]"
        >
          <el-input
            v-model="login.cpf.value"
            type="number"
            placeholder="Informe seu CPF"
            autocomplete="off"
            :controls="false"
            size="large"
            @keypress.enter.prevent="submitAuth"
          >
          </el-input>
        </el-form-item>
        <el-form-item
          label="Senha"
          prop="password.value"
          :rules="[
            {
              required: true,
              message: 'Por favor, informe uma senha válido.',
              trigger: ['blur', 'change'],
            },
            {
              min: 8,
              message: 'A senha deve possuir no mínimo 8 caracteres.',
              trigger: ['blur'],
            },
          ]"
        >
          <el-input
            v-model="login.password.value"
            type="password"
            placeholder="Digite sua senha"
            autocomplete="off"
            size="large"
            show-password
            @keypress.enter.prevent="submitAuth"
          >
          </el-input>
        </el-form-item>
        <el-divider />
        <el-form-item>
          <div class="button-container">
            <el-button
              class="button"
              size="large"
              @click="$router.push({ path: '/' })"
              >Voltar</el-button
            >
            <el-button
              class="button"
              type="primary"
              :loading="loading"
              :disabled="!(login.cpf.value?.length === 11 && login.password.value?.length >= 8 )"
              size="large"
              @click.prevent="submitAuth"
              >Entrar</el-button
            >
          </div>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import AuthService from "../services/auth.service";

export default {
  name: "Login",
  data() {
    return {
      login: {
        cpf: { value: null },
        password: { value: null },
      },
      loading: false,
    };
  },
  mounted() {
    this.authService = new AuthService()
  },
  methods: {
    async submitAuth() { 
      this.loading = true;
      await this.authService
        .login({ username: this.login.cpf.value, password: this.login.password.value })
        .then((data) => {
          localStorage.setItem('av_token', data.user.token_session)
          const { rolesPermissioned } = data
          if(rolesPermissioned.find(({name}) => name.includes('stic'))) this.$router.push("/app.dcc/dashboard")
          else if(rolesPermissioned.find(({name}) => name.includes('divpol.chefia'))) this.$router.push("/app.dcc/dashboard")
          else if(rolesPermissioned.find(({name}) => name.includes('divpol.spessoal'))) this.$router.push("/app.dcc/dashboard")
          else if(rolesPermissioned.find(({name}) => name.includes('chefia'))) this.$router.push("/app.dcc/list")
          throw new Error({Error: `Falha no nível de permissão do usuário.`})
        })
        .catch((err) => {
          console.log(err);
          this.loading = false
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
};
</script>

<style scoped>
.card-container {
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.title-container {
  max-width: 400px;
  display: flex;
  column-gap: 1rem;
  margin-bottom: 1rem;
}
.title-container > img {
  width: 100px;
}
.title-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.card-content {
  max-width: 400px;
  width: 100%;
}
.card-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
}
.button-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  justify-content: stretch;
}
.button {
  width: 100%;
  font-size: 1.2rem;
}
</style>